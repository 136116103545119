import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import Navbar from './Supportingfiles/navbar';
import axios from './../axios.js';


function Employeelogin(){

  
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
    
  //username
  const[username,setusername]=useState();const updateusername = (event) => { setusername(event.target.value);}; const[username_er,setusername_er]=useState();
  //branch_code
  const[branch_code,setbranch_code]=useState();const updatebranch_code = (event) => { setbranch_code(event.target.value);};  const[branch_code_er,setbranch_code_er]=useState();
  //password
  const[password,setpassword]=useState();const updatepassword = (event) => { setpassword(event.target.value);};  const[password_er,setpassword_er]=useState();

  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();
    

  //Redirecting to Main Admin if LoggedIn or Sessions Set
  useEffect(() => { if((logstatus=="true")&&(logid>0)&&(token!=" ")){       window.location.replace("/adminpanel-dashboard-customers");   } });



  /***************** EMPLOYEE LOGIN FUNCTION START  ******************/
   function employeelogin(){


  //Setting Error Variables
  setusername_er(" ");
  setpassword_er(" ");
  setbranch_code_er(" ");
  seterror_msg(" ");

   
    //Fetching Form Data
    let noerror = 0;let enoerror = 0;// let $username =""; let $email=""; 
    const $username = document.getElementById("username").value; if($username==""){ setusername_er("Username is required !");noerror = noerror+1; }//Fetching Username Value
    const $password = document.getElementById("password").value; if($password==""){ setpassword_er("Password is required !");noerror = noerror+1;enoerror = enoerror+1; }//Fetching Password Value
    const $branch_code = document.getElementById("branch_code").value; if($branch_code==""){ setbranch_code_er("Branch Code is required !");noerror = noerror+1;enoerror = enoerror+1; }//Fetching Branch Code Value


  //No primary errors
if((noerror==0)||(enoerror==0)){
    //Sending Data through axios
    axios.post("crm-employee-login", { 
     Username:$username,
     Password:$password,
     Branch_code:$branch_code
   
})

   .then((response) => {


     //Validation Errors
   if(response.data.validatorerror){
     setusername_er(response.data.validatorerror.Username);
     setpassword_er(response.data.validatorerror.Password);
     setbranch_code_er(response.data.validatorerror.Branch_code);
   }
   //Invalid Entries
   if(response.data.error==102){ seterror_msg("Invalid Username or  Password!");  }
   if(response.data.error==104){ seterror_msg(response.data.error_msg);  }
   if(response.data.error==106){ seterror_msg("Cannot Login, Try Again!"); }

   //Success
   if(response.data.successvar==1){

    //Setting Sessions 
    sessionStorage.setItem("logId00", response.data.log_id); //Assign value to log_id 
    sessionStorage.setItem("logToken00", response.data.token); //Assign value to token
    sessionStorage.setItem("logStatus00", "true"); //Assign value to logstatus
    sessionStorage.setItem("logType00", "admin"); //Assign value to logtype
    sessionStorage.setItem("logName00", response.data.name); //Assign value to token
    sessionStorage.setItem("branchId00", response.data.branch_id); //Assign value to branch_id 

   document.getElementById("success-card").style.display="block";


   
   }
   //failure
   else{
  // seterror_msg("Invalid Username or Password!");
  return;

   
   }
 }    );

}
//Return Errors
else{

  

 return;
}

}
  /***************** EMPLOYEE LOGIN FUNCTION END  ******************/


return(
    <>

<html lang="en">
  <head>
    {/* Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Login</title>
    {/* plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/* endinject */}
    {/* Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/* End plugin css for this page */}
    {/* inject:css */}
    {/* endinject */}
    {/* Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/* End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
    </head>
    <body>
    {/* container-scroller */}
    <div class="container-scroller">

   {/* partial */}
   <div class="container-fluid page-body-wrapper">

        {/*}  <Navbar/>  */} 

        

          <div class="main-panel">

      

          <div class="d-flex justify-content-center">
          {/* SUCCESS CARD START */}
          <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Login Successful!!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="employee-dashboard-customers"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              {/* SUCCESS CARD END */}

              </div>
          <div class="content-wrapper pb-0">
            <div class="row d-flex justify-content-center">
              <div class="col-md-6 grid-margin stretch-card" style={{textAlign:"left"}}>
              <div class="card">
                  <div class="card-body">
                {/*}  <div style={{backgroundColor:"#1b5ece",textAlign:"center",borderRadius:"5px"}}>
                    <h2 class="card-title" style={{color:"#ffffff",padding:"1rem",fontSize:"1.2rem"}}>Admin Login</h2>
                    </div> */}

                    <div class="row" style={{backgroundColor:"#ffffff",textAlign:"center",borderRadius:"5px", borderBottom:"0.2rem solid #67BC45"}}>
                    <div class="col-md-4"><img src="/images/crmlogo.png" style={{height:"9rem", width:"9rem"}}></img></div>
                    <div class="col-md-8">
                    <h2 class="card-title" style={{color:"#14a56c", padding:"1rem" ,fontSize:"1.2rem", textAlign:"left"}}>PRAGATI PRATHMIK SAKH SAHKARI SAMITI MARYADIT <br/>
                    <br/><span style={{color:"#007489"}}>EMPLOYEE LOGIN</span></h2></div> 
                    </div>

                    <p  style={{textAlign:"center"}}><code>{error_msg}</code></p>
                    <div class="forms-sample">
                      <div class="form-group">
                        <label for="exampleInputUsername1">Username</label>
                        <input type="text" class="form-control" id="username" placeholder="Username" value={username} onChange={updateusername} />
                        <code>{username_er}</code>
                      </div>
                      <div class="form-group">
                        <label for="exampleInputUsername1">Branch Code</label>
                        <input type="text" class="form-control" id="branch_code" placeholder="Branch Code" value={branch_code} onChange={updatebranch_code} />
                        <code>{branch_code_er}</code>
                      </div>
                     
                  {/*}    <div class="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input type="email" class="form-control" id="email" placeholder="Email" value={email} onChange={updateemail} />
                        <code>{email_er}</code>
                      </div> */}
                      <div class="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" class="form-control" id="password" placeholder="Password" value={password} onChange={updatepassword} />
                        <code>{password_er}</code>
                      </div>
                
                     <button type="submit" id="login-btn" class="btn-lg btn-success me-2" onClick={employeelogin}>Login </button>
                      <br/>
                     {/*} <div style={{textAlign:"right"}}><a href="/businesstools-forgetpass">Forget Password</a></div>*/}
                    </div>
                 {/*}   <p><span><b>New User</b></span><a href="/businesstools-admin-register"> register here</a></p> */}
                  </div>
                </div>
              </div>

              

             </div>
             </div>
             </div>
             </div>
             </div>


    {/* plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/* endinject */}
    {/* Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/* End plugin js for this page */}
    {/* inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/* endinject */}
    {/* Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/* End custom js for this page */}
  </body>
  </html>    
    </>
);

}

export default Employeelogin;