import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_dashboard from './create_newaccounts';



import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

function Customer_dashboard(){



    
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const busyid=sessionStorage.getItem("BusyId00");
 const counterid=sessionStorage.getItem("CounterId00");

 /* PAGE VARIABLES */


 
{/* TRANSACTIONS TABLE COLUMNS PayMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
{/* const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14 */}



//FILTER VARIABLES
const[filter_val,setfilter_val] = useState(0);
const[filter_start_date,setfilter_start_date] = useState('1970-01-01'); const updatefilter_start_date = (event) => { setfilter_start_date(event.target.value);}; const[filter_start_date_er,setfilter_start_date_er]=useState();
const[filter_end_date,setfilter_end_date] = useState('2100-12-12'); const updatefilter_end_date = (event) => { setfilter_end_date(event.target.value);}; const[filter_end_date_er,setfilter_end_date_er]=useState();
const[filter_trno,setfilter_trno] = useState(); const updatefilter_trno = (event) => { setfilter_trno(event.target.value);}; const[filter_trno_er,setfilter_trno_er]=useState();
const[filter_transaction,setfilter_transaction] = useState(); const updatefilter_transaction = (event) => { setfilter_transaction(event.target.value);}; const[filter_transaction_er,setfilter_transaction_er]=useState();
const[filter_amount_in_sign,setfilter_amount_in_sign] = useState(); const updatefilter_amount_in_sign = (event) => { setfilter_amount_in_sign(event.target.value);}; const[filter_amount_in_sign_er,setfilter_amount_in_sign_er]=useState();
const[filter_amount_in,setfilter_amount_in] = useState(); const updatefilter_amount_in = (event) => { setfilter_amount_in(event.target.value);}; const[filter_amount_in_er,setfilter_amount_in_er]=useState();
const[filter_amount_out_sign,setfilter_amount_out_sign] = useState(); const updatefilter_amount_out_sign = (event) => { setfilter_amount_out_sign(event.target.value);}; const[filter_amount_out_sign_er,setfilter_amount_out_sign_er]=useState();
const[filter_amount_out,setfilter_amount_out] = useState(); const updatefilter_amount_out = (event) => { setfilter_amount_out(event.target.value);}; const[filter_amount_out_er,setfilter_amount_out_er]=useState();


//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);



//edate
const[edate,setedate]=useState();const updateedate = (event) => { setedate(event.target.value);}; const[edate_er,setedate_er]=useState();
//etype
const[etype,setetype]=useState();const updateetype = (event) => { setetype(event.target.value);}; const[etype_er,setetype_er]=useState();
//eparticular
const[eparticular,seteparticular]=useState();const updateeparticular = (event) => { seteparticular(event.target.value);}; const[eparticular_er,seteparticular_er]=useState();
//eamount
const[eamount,seteamount]=useState();const updateeamount = (event) => { seteamount(event.target.value);}; const[eamount_er,seteamount_er]=useState();


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();

function getData(){}

function editcash(){}

function getcounterdatabyId(){}


 
//Variables
const[data,setData] = useState([]);//SetData Variable

return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Customer Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

    {/*}  <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          <div className="row">
              <div className="col-12 grid-margin create-form" id="create-form">
             {/*} <Create_cash /> */}
              </div>
          </div>


            <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">CUSTOMER PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">DASHBOARD</p>
                  </a>
                </div>
            
              </div>
            </div>

            {/*} chart row starts here */}
            <div className="row">

          
           

            
            <div className="col-lg-3 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">MY PROFILE</h4>
                    <p className="card-description">view your profile here
                    </p>
                    <img src="https://img.freepik.com/free-vector/profile-interface-concept-illustration_114360-3904.jpg?semt=sph" style={{height:"34vh", width:"100%"}}></img>
                    <a href="/customer-dashboard-profile"><button class="btn btn-lg mt-3" style={{backgroundColor:"#d481c8", color:"#ffffff"}}>View</button></a>
            </div>
            </div>
            </div>
            <div className="col-lg-3 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">MY LOANS</h4>
                    <p className="card-description">view your loans here
                    </p>
                    <img src="https://img.freepik.com/free-vector/business-support-concept-illustration_114360-9176.jpg?semt=sph" style={{height:"34vh", width:"100%"}}></img>
                    <a href="/customer-dashboard-loans"><button class="btn btn-lg mt-3" style={{backgroundColor:"#d481c8", color:"#ffffff"}}>View</button></a>
            </div>
            </div>
            </div>
            <div className="col-lg-3 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">PROFILE UPDATION HISTORY</h4>
                    <p className="card-description">view profile updates history here
                    </p>
                    <img src="https://img.freepik.com/free-vector/add-friends-concept-illustration_114360-5538.jpg?semt=ais_user" style={{height:"34vh", width:"100%"}}></img>
                    <a href="/customer-dashboard-updations"><button class="btn btn-lg mt-3" style={{backgroundColor:"#d481c8", color:"#ffffff"}}>View</button></a>

            </div>
            </div>
            </div>
            <div className="col-lg-3 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">LOAN EMI HISTORY</h4>
                    <p className="card-description">view loan emi history here
                    </p>
                    <img src="https://img.freepik.com/free-vector/e-wallet-concept-illustration_114360-7711.jpg?semt=ais_user" style={{height:"34vh", width:"100%"}}></img>
                    <a href="/customer-dashboard-emis-history"><button class="btn btn-lg mt-3" style={{backgroundColor:"#d481c8", color:"#ffffff"}}>View</button></a>
            </div>
            </div>
            </div>
            </div>

          
                    
    
               
            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Customer_dashboard;