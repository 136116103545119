import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';


import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import moment from 'moment/moment';


function Admin_create_offer_letter(){

  //const imgpath = 'http://127.0.0.1:8000/storage/';
  const imgpath = 'https://pragatiprathmik.com/api/';
  
  //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
  

 //SETTING DATA VARIABLES
 const[date,setdate]=useState();
 const[offer_id,setoffer_id]=useState();
 const[employee_code,setemployee_code]=useState();
 const[employee_name,setemployee_name]=useState();
 const[working_city,setworking_city]=useState();
 const[working_state,setworking_state]=useState();
 const[date_of_joining,setdate_of_joining]=useState();
 const[join_before,setjoin_before]=useState();
 const[department,setdepartment]=useState();
 const[designation,setdesignation]=useState();
 const[reporting_manager,setreporting_manager]=useState();
 const[gross_salary,setgross_salary]=useState(0);
 const[gross_salary_inwords,setgross_salary_inwords]=useState();
 const[basic_salary,setbasic_salary]=useState(0);
 const[house_rental_allowance,sethouse_rental_allowance]=useState(0);
 const[travel_allowance,settravel_allowance]=useState(0);
 const[dearance_allowance,setdearance_allowance]=useState(0);
 const[special_allowance,setspecial_allowance]=useState(0);
 const[incentives,setincentives]=useState(0);
 const[deductions,setdeductions]=useState(0);
 const[pf_contribution,setpf_contribution]=useState(0);
 const[fixed_ctc,setfixed_ctc]=useState(0);
 const[hr_manager,sethr_manager]=useState();
 const[incentive_remarks,setincentive_remarks]=useState();


  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/admin-dashboard-employee-offer-letter', { state: { date, offer_id, employee_code, employee_name, working_city, working_state, date_of_joining, join_before, department, designation, reporting_manager, gross_salary, gross_salary_inwords, basic_salary, house_rental_allowance, travel_allowance, dearance_allowance, special_allowance, incentives, deductions, pf_contribution, fixed_ctc, hr_manager, incentive_remarks } });
  };







return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

         


          <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">CREATE NEW OFFER LETTER</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Create New Offer Letter</h4>
                    <div class="form-sample">
                      <p class="card-description">create new offer letter</p>
               {/*}       <code>{error_msg}</code> */}

                      <form onSubmit={handleSubmit}>
     
   

                     <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date</label>
                            <div class="col-sm-9">
                              <input type="date" class="form-control" placeholder="24-08-2024" id="date" value={date} onChange={(e) => setdate(e.target.value)} />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Offer Id</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="JHS7S878" id="offer_id" value={offer_id} onChange={(e) => setoffer_id(e.target.value)} />
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Employee Code</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="EMP99898" id="employee_code" value={employee_code} onChange={(e) => setemployee_code(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Employee Name</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="MR. KARAN KUMAR" id="employee_name" value={employee_name} onChange={(e) => setemployee_name(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Working City</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Satna" id="working_city" value={working_city} onChange={(e) => setworking_city(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Working State</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Madhya Pradesh" id="working_state" value={working_state} onChange={(e) => setworking_state(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Date of Joining</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="7th September 2024, Friday" id="date_of_joining" value={date_of_joining} onChange={(e) => setdate_of_joining(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Reporting Date</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5th September" id="join_before" value={join_before} onChange={(e) => setjoin_before(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Department</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Business Development, Sales" id="department" value={department} onChange={(e) => setdepartment(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Designation</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="Head of Sales" id="designation" value={designation} onChange={(e) => setdesignation(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Reporting Manager</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="MR. KAMAL RAJAK" id="reporting_manager" value={reporting_manager} onChange={(e) => setreporting_manager(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gross Salary</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="280000" id="gross_salary" value={gross_salary} onChange={(e) => setgross_salary(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Gross Salary in words</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="RUPEES TWENTY EIGHT THOUSAND ONLY" id="gross_salary_inwords" value={gross_salary_inwords} onChange={(e) => setgross_salary_inwords(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Basic Pay</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="280000" id="basic_salary" value={basic_salary} onChange={(e) => setbasic_salary(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">House Rental Allowance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="8000" id="house_rental_allowance" value={house_rental_allowance} onChange={(e) => sethouse_rental_allowance(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Travel Allowance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="80000" id="travel_allowance" value={travel_allowance} onChange={(e) => settravel_allowance(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Dearance Allowance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="7000" id="dearance_allowance" value={dearance_allowance} onChange={(e) => setdearance_allowance(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Special Allowance</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="7000" id="special_allowance" value={special_allowance} onChange={(e) => setspecial_allowance(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Incentives</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="7000" id="incentives" value={incentives} onChange={(e) => setincentives(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                          <label class="col-sm-3 col-form-label">Incentive Remarks</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000" id="incentive_remarks" value={incentive_remarks} onChange={(e) => setincentive_remarks(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Deductions</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="10000" id="deductions" value={deductions} onChange={(e) => setdeductions(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                          <label class="col-sm-3 col-form-label">PF Contribution</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="5000" id="pf_contribution" value={pf_contribution} onChange={(e) => setpf_contribution(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Fixed CTC</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="70000" id="fixed_ctc" value={fixed_ctc} onChange={(e) => setfixed_ctc(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        <div class="col-md-6">
                          <div class="form-group row">
                          <label class="col-sm-3 col-form-label">HR Manager</label>
                            <div class="col-sm-9">
                              <input type="text" class="form-control" placeholder="MR. SOHAN VERMA" id="hr_manager" value={hr_manager} onChange={(e) => sethr_manager(e.target.value)} />
                            </div>
                          </div>
                        </div> 
                        </div>
                        <button type="submit" class="btn btn-lg btn-success" style={{fontWeight:"bold"}}>Submit</button>
                        </form>


               
                     
                   
                    </div>
                  </div>
                </div>

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_create_offer_letter;