import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';
import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


function Admin_view_salary(){

   //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://pragatiprathmik.com/api/';
  const imgpath = 'https://api.pragatiprathmik.com/storage/';

  
    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });


 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");

 
 //SETTING DATA VARIABLES
 const[tr_no,settr_no]=useState();
 const[date,setdate]=useState();
 const[transaction,settransaction]=useState();
 const[previous_salary_balance,setprevious_salary_balance]=useState();
 const[working_days,setworking_days]=useState();
 const[basic_pay,setbasic_pay]=useState();
 const[incentives,setincentives]=useState();
 const[travel_allowance,settravel_allowance]=useState();
 const[dearance_allowance,setdearance_allowance]=useState();
 const[provident_fund,setprovident_fund]=useState();
 const[salary_deductions,setsalary_deductions]=useState();
 const[salary_payable,setsalary_payable]=useState();
 const[salary_paid,setsalary_paid]=useState();
 const[balance_salary,setbalance_salary]=useState();
 const[transaction_id,settransaction_id]=useState();
 const[mode,setmode]=useState();
 const[bank_id,setbank_id]=useState();
 const[bank_name,setbank_name]=useState();
 const[employee_id,setemployee_id]=useState();
 const[employee_code,setemployee_code]=useState();
 const[branch_code,setbranch_code]=useState();
 const[first_name,setfirst_name]=useState();
 const[last_name,setlast_name]=useState();
 const[email,setemail]=useState();
 const[contact,setcontact]=useState();
 const[salary_remarks,setsalary_remarks]=useState();
 const[attachment_link,setattachment_link]=useState();


  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* VIEW LOAN APPLICATION FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
 
 axios.post("crm-admin-view-employee-salary", {
   Log_id:logid,
   Token:token,
   Edit_id:editId,


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Branch Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   settr_no(response.data.tr_no);
   setdate(response.data.date);
   settransaction(response.data.transaction);
   setprevious_salary_balance(response.data.previous_salary_balance);
   setworking_days(response.data.working_days);
   setbasic_pay(response.data.basic_pay);
   setincentives(response.data.incentives);
   settravel_allowance(response.data.travel_allowance);
   setdearance_allowance(response.data.dearance_allowance);
   setprovident_fund(response.data.provident_fund);
   setsalary_deductions(response.data.salary_deductions);
   setsalary_payable(response.data.salary_payable);
   setsalary_paid(response.data.salary_paid);
   setbalance_salary(response.data.balance_salary);
   settransaction_id(response.data.transaction_id);
   setmode(response.data.mode);
   setbank_id(response.data.bank_id);
   setbank_name(response.data.bank_name);
   setattachment_link((response.data.attachment_link).slice(7));
   setemployee_id(response.data.employee_id);
   setemployee_code(response.data.employee_code);
   setbranch_code(response.data.branch_code);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setemail(response.data.email);
   setcontact(response.data.contact);
   setsalary_remarks(response.data.salary_remarks);
  
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* VIEW LOAN APPLICATION FUNCTION END  ********************//


 
 


return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

          <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">VIEW SALARY PAYMENT</p>
                  </a>
                  
                </div>
              
      
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                  <div class="top"  id="invoice" ref={componentRef}>
                    <h3 class="card-title">EMPLOYEE DETAILS</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>
                    
                    <div class="row" style={{textAlign:"left"}}>

                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Employee Code</b></p></div>
                    <div class="row col-sm-6"> <p>{employee_code}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Employee Name:</b></p></div>
                    <div class="row col-sm-6"> <p>{first_name} {last_name}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-6"> <p>{contact}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Email:</b></p></div>
                    <div class="row col-sm-6"> <p>{email}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Branch Code:</b></p></div>
                    <div class="row col-sm-6"> <p>{branch_code}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Transaction:</b></p></div>
                    <div class="row col-sm-6"> <p>
                    <label className="badge badge-success" hidden={transaction=="create salary"}>{transaction}</label>
                    <label className="badge badge-warning" hidden={transaction=="create and pay salary"}>{transaction}</label>
                    </p></div>
                    </div>
                

                      
                      <hr/>


                      <h3 class="card-title">SALARY DETAILS</h3>
              
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Tr No.:</b></p></div>
                    <div class="row col-sm-6"> <p>{tr_no}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Date:</b></p></div>
                    <div class="row col-sm-6"> <p>{date}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Transaction Id:</b></p></div>
                    <div class="row col-sm-6"> <p>{transaction_id}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Mode:</b></p></div>
                    <div class="row col-sm-6"> <p>{mode}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Bank:</b></p></div>
                    <div class="row col-sm-6"> <p>{bank_name}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Working Days:</b></p></div>
                    <div class="row col-sm-6"> <p>{working_days}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Previous Salary Balance:</b></p></div>
                    <div class="row col-sm-6"> <p>{previous_salary_balance}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Basic Pay:</b></p></div>
                    <div class="row col-sm-6"> <p>{basic_pay}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Incentives:</b></p></div>
                    <div class="row col-sm-6"> <p>{incentives}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Travel Allowance:</b></p></div>
                    <div class="row col-sm-6"> <p>{travel_allowance}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Dearance Allowance:</b></p></div>
                    <div class="row col-sm-6"> <p>{dearance_allowance}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Provident Fund:</b></p></div>
                    <div class="row col-sm-6"> <p>{provident_fund}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Salary Deductions:</b></p></div>
                    <div class="row col-sm-6"> <p>{salary_deductions}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Salary Payable:</b></p></div>
                    <div class="row col-sm-6"> <p>{salary_payable}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Salary Paid:</b></p></div>
                    <div class="row col-sm-6"> <p>{salary_paid}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Balance Salary (c.f.):</b></p></div>
                    <div class="row col-sm-6"> <p>{balance_salary}</p></div>
                    </div>
             

                    <hr />
                    <h3 class="card-title">DOCUMENTS AND ATTACHMENTS</h3>
                    <div class="row col-md-12">
                    <div class="row col-sm-6"> <p><b>Remarks:</b></p></div>
                    <div class="row col-sm-6"> <p>{salary_remarks}</p></div>
                    </div>
                    </div>
                  
                    </div>
                    <button type="button" style={{backgroundColor:"#196fb0",marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
                    </button>
                    </div>
                  
                    <row class="row mt-2">
                    <div class="row col-md-12"><img src={imgpath+attachment_link} style={{height:"90vh", width:"100%"}} /></div>
                    </row>
                   
                    
                    </div>
                  </div>
                
                
               

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_view_salary;