import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_customer_loans from './create_newaccounts';
import {useRef as useref} from 'react';
import {useReactToPrint} from 'react-to-print';


import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

function Admin_customer_loans(){



    //Invoice Print Function
    const componentRef = useref();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const custId=sessionStorage.getItem("CustomerId00");

 /* PAGE VARIABLES */


 
{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
{/* const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14 */}





//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);



 //Error Variable
 const[errorvar,seterrorvar] = useState();



 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);
const[filter_application_no,setfilter_application_no]=useState();const updatefilter_application_no = (event) => { setfilter_application_no(event.target.value);}; const[filter_application_no_er,setfilter_application_no_er]=useState();
const[filter_loan_amount,setfilter_loan_amount]=useState();const updatefilter_loan_amount = (event) => { setfilter_loan_amount(event.target.value);}; const[filter_loan_amount_er,setfilter_loan_amount_er]=useState();
const[filter_scheme_code,setfilter_scheme_code]=useState();const updatefilter_scheme_code = (event) => { setfilter_scheme_code(event.target.value);}; const[filter_scheme_code_er,setfilter_scheme_code_er]=useState();
const[filter_interest_rate,setfilter_interest_rate]=useState();const updatefilter_interest_rate = (event) => { setfilter_interest_rate(event.target.value);}; const[filter_interest_rate_er,setfilter_interest_rate_er]=useState();
const[filter_duration,setfilter_duration]=useState();const updatefilter_duration = (event) => { setfilter_duration(event.target.value);}; const[filter_duration_er,setfilter_duration_er]=useState();

 
//Variables
const[data,setData] = useState([]);//SetData Variable


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 





//******************* GET CUSTOMER LOANS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(custId>0)){
 
 axios.post("crm-admin-get-customer-loans-list", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

   //Filters
   'Filter_val' :filterval,
   'Customer_id' : custId,
   'Filter_scheme_code' :filter_scheme_code,
   'Filter_application_no' :filter_application_no,
   'Filter_loan_amount' :filter_loan_amount,
   'Filter_interest_rate' :filter_interest_rate,
   'Filter_duration' :filter_duration,

   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){

    setfilter_scheme_code_er(response.data.validatorerror.Filter_scheme_code);
    setfilter_application_no_er(response.data.validatorerror.Filter_application_no);
    setfilter_loan_amount_er(response.data.validatorerror.Filter_loan_amount);
    setfilter_interest_rate_er(response.data.validatorerror.Filter_interest_rate);
    setfilter_duration_er(response.data.validatorerror.Filter_duration);
    seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
     //Setting Filter Violation Errors
     setfilter_scheme_code_er(response.data.filter_scheme_code);
     setfilter_application_no_er(response.data.filter_application_no);
     setfilter_loan_amount_er(response.data.filter_loan_amount);
     setfilter_interest_rate_er(response.data.filter_interest_rate);
     setfilter_duration_er(response.data.filter_duration);
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET CUSTOMER LOANS DATA FUNCTION END  ********************//

return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

    {/*}  <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          <div className="row">
              <div className="col-12 grid-margin create-form" id="create-form">
             {/*} <Create_cash /> */}
              </div>
          </div>


            <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">CUSTOMER LOANS</p>
                  </a>
                </div>
               <a href="/employee-dashboard-customer-loan-application"><button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text">
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button> </a>
              </div>
            </div>

            {/*} chart row starts here */}
            <div className="row">

          
            </div>

            

              {/* CASH TRANSACTIONS TABLE SECTION START */}
                  
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Loan Requests</h4>
                    <p className="card-description">manage all loan requests
                    </p>
                    <code>{error_msg}</code>

{/* FILTER SECTION START */}
<hr/>
   <div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
   <div class="d-flex justify-content-between">
   <div>
   <h4 style={{padding:'1rem'}}>Filters</h4>
  </div>
  <div>

  <button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
  <i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
  </button>
  </div>
  </div>

<div class="row">
<div class="col-md-4">
 <div class="form-group row">
   <label class="col-sm-4 col-form-label">Scheme Code</label>
   <div class="col-sm-8">
     <input type="text" class="form-control" placeholder="SCH1234567" id="filter_scheme_code" value={filter_scheme_code} onChange={updatefilter_scheme_code}  />
     <code>{filter_scheme_code_er}</code>
   </div>
 </div>
</div>
<div class="col-md-4">
 <div class="form-group row">
   <label class="col-sm-4 col-form-label">Application No.</label>
   <div class="col-sm-8">
     <input type="text" class="form-control" placeholder="LN123456788" id="filter_application_no" value={filter_application_no} onChange={updatefilter_application_no}  />
     <code>{filter_application_no_er}</code>
   </div>
 </div>
</div>
<div class="col-md-4">
 <div class="form-group row">
   <label class="col-sm-4 col-form-label">Loan Amount</label>
   <div class="col-sm-8">
     <input type="text" class="form-control" placeholder="12000.00"   id="filter_loan_amount" value={filter_loan_amount} onChange={updatefilter_loan_amount}  />
     <code>{filter_loan_amount_er}</code>
   </div>
 </div>
</div>
</div>
<div class="row">
<div class="col-md-4">
 <div class="form-group row">
   <label class="col-sm-4 col-form-label">Interest Rate</label>
   <div class="col-sm-8">
     <input type="text" class="form-control" placeholder="12.00"   id="filter_interest_rate" value={filter_interest_rate} onChange={updatefilter_interest_rate}  />
     <code>{filter_interest_rate_er}</code>
   </div>
 </div>
</div>
   <div class="col-md-4">
 <div class="form-group row">
   <label class="col-sm-4 col-form-label">Duration <br/> (in weeks)</label>
   <div class="col-sm-8">
     <input type="text" class="form-control" placeholder="24"   id="filter_duration" value={filter_duration} onChange={updatefilter_duration}  />
     <code>{filter_duration_er}</code>
   </div>
 </div>
 </div>
 <div class="col-md-4">
   <button class="btn btn-primary" onClick={()=>{ setfilterval(1); getData(); }}>Apply Filters</button>
 </div>



</div>


   <hr/>                  
   </div> 
   
 {/*}   FILTER SECTION END */}


                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                      {/*}    <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button> */}
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Transaction</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Particular</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount In</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount Out</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              
                           
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div className="table-responsive"  id="invoice" ref={componentRef}>
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr >
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Loan/Application No.</th>
                            <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Date</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Scheme Code</th>
                         {/*}   <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Branch</th> */}
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Loan Amount</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Interest Rate</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Loan Duration <br/><span style={{marginTop:"1rem"}}>(in weeks)</span></th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >EMI</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >EMIs paid</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >EMIs due</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Status</th>
                          {/*}  <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Request By</th> */}
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Updated At</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >Manage</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                        <tr>
                          <td>{curElm.Application_no}</td>
                          <td>{curElm.Date_created}</td>
                          <td>{curElm.Scheme_code}</td>
                          <td>{curElm.Loan_amount}</td>
                          <td>{curElm.Interest_rate}</td>
                          <td>{curElm.Duration} weeks</td>
                          <td>{curElm.EMI}</td>
                          <td>{curElm.EMIs_paid}</td>
                          <td>{curElm.EMIs_due}</td>
                          <td>
                          <label className="badge badge-success" hidden={(curElm.Loan_status=="under review")||(curElm.Loan_status=="rejected")||(curElm.Loan_status=="completed")}>approved</label>
                          <label className="badge badge-warning" hidden={(curElm.Loan_status=="approved")||(curElm.Loan_status=="rejected"||(curElm.Loan_status=="completed"))}>under review</label>
                          <label className="badge badge-danger" hidden={(curElm.Loan_status=="under review")||(curElm.Loan_status=="approved")||(curElm.Loan_status=="completed")}>rejected</label>                       
                          <label className="badge badge-primary" hidden={(curElm.Loan_status=="under review")||(curElm.Loan_status=="approved"||(curElm.Loan_status=="rejected"))}>completed</label>                       

                          </td>
                         {/* <td>Sohan (EMP ID: 82222)</td> */}
                          <td>{curElm.Date_updated} ({curElm.Time_updated})</td>
                         <td><button class="btn btn-primary" onClick={()=>{
                          sessionStorage.setItem("LNO00", curElm.Application_no);
                          window.location.replace("/adminpanel-dashboard-customer-emis");
                         }}>Manage</button></td>
                        </tr>
                       
                        </>
                         )
                         }
					   
	                  		 )
                         }
                     {/*}    <tr>
                          <td>9</td>
                          <td>27-08-2024</td>
                          <td>LN909994567</td>
                          <td>SC24111</td>
                          <td>52000.00</td>
                          <td>18.00 %</td>
                          <td>26 weeks</td>
                          <td>2500.00</td>
                          <td></td>
                          <td></td>
                          <td> <td><label className="badge badge-warning">under review</label></td></td>
                         {/* <td>Sohan (EMP ID: 82222)</td> 
                          <td>12-09-2021 (3:12am)</td>
                         <td><a href="/employee-dashboard-customer-emis"><button class="btn btn-primary">Manage</button></a></td>
                        </tr> */}
               
                         
                        </tbody>
                      </table>
                    </div>
                    
                    {/* PAGINATION SECTION START */}
                    <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                  </div>
                </div>
              </div>

            {/* CASH TRANSACTIONS TABLE SECTION END */}

            <button type="button" style={{backgroundColor:"#196fb0",marginTop:"2rem",marginLeft:"2rem"}} class="btn btn-secondary btn-icon-text" onClick={handlePrint}> Print <i class="mdi mdi-file-document"></i>
            </button>
            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Admin_customer_loans;