import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_loanrequests from './create_newaccounts';



function Employee_view_loan_application(){


  //const imgpath = 'http://127.0.0.1:8000/storage/';
  //const imgpath = 'https://pragatiprathmik.com/api/';
  const imgpath = 'https://api.pragatiprathmik.com/storage/';

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");
 const editId=sessionStorage.getItem("EditId00");
 const branch_id=sessionStorage.getItem("branchId00");

 
 //SETTING DATA VARIABLES
 const[application_no,setapplication_no]=useState();
 const[customer_id,setcustomer_id]=useState();
 const[branch_code,setbranch_code]=useState();
 const[account_number,setaccount_number]=useState();
 const[first_name,setfirst_name]=useState();
 const[last_name,setlast_name]=useState();
 const[email,setemail]=useState();
 const[contact,setcontact]=useState();
 const[scheme_code,setscheme_code]=useState();
 const[scheme_name,setscheme_name]=useState();
 const[loan_amount,setloan_amount]=useState();
 const[interest_rate,setinterest_rate]=useState();
 const[pf_per,setpf_per]=useState();
 const[pf_amount,setpf_amount]=useState();
 const[death_insurance,setdeath_insurance]=useState();
 const[health_insurance,sethealth_insurance]=useState();
 const[gst_per,setgst_per]=useState();
 const[gst_amount,setgst_amount]=useState();
 const[total_additions,settotal_additions]=useState();
 const[processing_fee,setprocessing_fee]=useState();
 const[loan_interest,setloan_interest]=useState();
 const[duration,setduration]=useState();
 const[minimum_monthly_income,setminimum_monthly_income]=useState();
 const[repayable_amount,setrepayable_amount]=useState();
 const[emi,setemi]=useState();
 const[monthly_income,setmonthly_income]=useState();
 const[account_type,setaccount_type]=useState();
 const[cibil_score,setcibil_score]=useState();
 const[date_of_cibil,setdate_of_cibil]=useState();
 const[loan_purpose,setloan_purpose]=useState();
 const[bank_name,setbank_name]=useState();
 const[bank_person_name,setbank_person_name]=useState();
 const[ifsc_code,setifsc_code]=useState();
 const[bank_account_number,setbank_account_number]=useState();
 const[application_remarks,setapplication_remarks]=useState();
 const[document_file_name,setdocument_file_name]=useState();
 const[document_file_link,setdocument_file_link]=useState();
 const[cibil_proof_image,setcibil_proof_image]=useState();
 const[self_certification_image_link,setself_certification_image_link]=useState();
 const[loan_status,setloan_status]=useState();
 const[application_status,setapplication_status]=useState();
 const[employee_remarks,setemployee_remarks]=useState();
 const[loan_remarks,setloan_remarks]=useState();
 const[emis_paid,setemis_paid]=useState();
 const[emis_due,setemis_due]=useState();


  //ERROR VARIABLES 
  const[error_msg,seterror_msg]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 

//******************* VIEW LOAN APPLICATION FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)&&(branch_id>0)){
 
 axios.post("crm-employee-view-loan-application", {
   Log_id:logid,
   Token:token,
   Edit_id:editId,
   Branch_id:branch_id


   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");
     sessionStorage.removeItem("EditId00");
     window.location.replace("/");
  }

  //Data Not Found
  if((response.data.error)==103){  seterror_msg("Data Not Found for Branch Id !");}

  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setapplication_no(response.data.application_no);
   setcustomer_id(response.data.customer_id);
   setaccount_number(response.data.account_number);
   setbranch_code(response.data.branch_code);
   setaccount_number(response.data.account_number);
   setfirst_name(response.data.first_name);
   setlast_name(response.data.last_name);
   setcontact(response.data.contact);
   setemail(response.data.email);
   setscheme_code(response.data.scheme_code);
   setscheme_name(response.data.scheme_name);
   setloan_amount(response.data.loan_amount);
   setinterest_rate(response.data.interest_rate);
   setpf_per(response.data.pf_per);
   setpf_amount(response.data.pf_amount);
   setdeath_insurance(response.data.death_insurance);
   sethealth_insurance(response.data.health_insurance);
   setgst_per(response.data.gst_per);
   setgst_amount(response.data.gst_amount);
   settotal_additions(response.data.total_additions);
   setprocessing_fee(response.data.processing_fee);
   setloan_interest(response.data.loan_interest);
   setduration(response.data.duration);
   setminimum_monthly_income(response.data.minimum_monthly_income);
   setrepayable_amount(response.data.repayable_amount);
   setemi(response.data.emi);
   setmonthly_income(response.data.monthly_income);
   setaccount_type(response.data.account_type);
   setcibil_score(response.data.cibil_score);
   setdate_of_cibil(response.data.date_of_cibil);
   setloan_purpose(response.data.loan_purpose);
   setbank_name(response.data.bank_name);
   setbank_person_name(response.data.bank_person_name);
   setifsc_code(response.data.ifsc_code);
   setbank_account_number(response.data.bank_account_number);
   setapplication_remarks(response.data.application_remarks);
   setdocument_file_name(response.data.document_file_name);
   setdocument_file_link(response.data.document_file_link);
   setcibil_proof_image((response.data.cibil_proof_image).slice(7));
   setself_certification_image_link((response.data.self_certification_image_link).slice(7));
   setloan_status(response.data.loan_status);
   setapplication_status(response.data.application_status);
   setemployee_remarks(response.data.employee_remarks);
   setloan_remarks(response.data.loan_remarks);
   setemis_paid(response.data.emis_paid);
   setemis_due(response.data.emis_due);

  }

  //FILTER DATA
  //Validation Errors
  if(response.data.validatorerror){ seterror_msg("Validation error!"); }

  //Data Violation
  if(response.data.error==102){  seterror_msg("Violation Error"); }

 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");
   sessionStorage.removeItem("EditId00");
   window.location.replace("/");
 
  }
 
 }
  
 //******************* VIEW LOAN APPLICATION FUNCTION END  ********************//

 

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          


          <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">LOAN APPLICATION</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h3 class="card-title">CUSTOMER DETAILS</h3>
                    <div class="form-sample" style={{textAlign:"left"}}>
                    
                    <div class="row" style={{textAlign:"left"}}>

                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Customer Id:</b></p></div>
                    <div class="row col-sm-6"> <p>{customer_id}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Customer Name:</b></p></div>
                    <div class="row col-sm-6"> <p>{first_name} {last_name}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Contact:</b></p></div>
                    <div class="row col-sm-6"> <p>{contact}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Email:</b></p></div>
                    <div class="row col-sm-6"> <p>{email}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Account Number:</b></p></div>
                    <div class="row col-sm-6"> <p>{account_number}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Branch Code:</b></p></div>
                    <div class="row col-sm-6"> <p>{branch_code}</p></div>
                    </div>
                

                      
                      <hr/>


                      <h3 class="card-title">LOAN DETAILS</h3>
              
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Application No.:</b></p></div>
                    <div class="row col-sm-6"> <p>{application_no}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Scheme Code:</b></p></div>
                    <div class="row col-sm-6"> <p>{scheme_code}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Scheme Name:</b></p></div>
                    <div class="row col-sm-6"> <p>{scheme_name}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Loan Amount:</b></p></div>
                    <div class="row col-sm-6"> <p>{loan_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Interest Rate:</b></p></div>
                    <div class="row col-sm-6"> <p>{interest_rate}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Interest Amount:</b></p></div>
                    <div class="row col-sm-6"> <p>{loan_interest}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>PF %:</b></p></div>
                    <div class="row col-sm-6"> <p>{pf_per}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>PF Amount:</b></p></div>
                    <div class="row col-sm-6"> <p>{pf_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Health Insurance:</b></p></div>
                    <div class="row col-sm-6"> <p>{health_insurance}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>GST %:</b></p></div>
                    <div class="row col-sm-6"> <p>{gst_per}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>GST Amount:</b></p></div>
                    <div class="row col-sm-6"> <p>{gst_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Death Insurance:</b></p></div>
                    <div class="row col-sm-6"> <p>{death_insurance}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Duration:</b></p></div>
                    <div class="row col-sm-6"> <p>{duration}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Repayable Amount:</b></p></div>
                    <div class="row col-sm-6"> <p>{repayable_amount}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>EMI:</b></p></div>
                    <div class="row col-sm-6"> <p>{emi}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Monthly Income:</b></p></div>
                    <div class="row col-sm-6"> <p>{monthly_income}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Cibil Score:</b></p></div>
                    <div class="row col-sm-6"> <p>{cibil_score}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Date of Cibil:</b></p></div>
                    <div class="row col-sm-6"> <p>{date_of_cibil}</p></div>
                    </div>

                    <hr/>
                    <h3 class="card-title">BANK DETAILS</h3>

                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Name as per Bank:</b></p></div>
                    <div class="row col-sm-6"> <p>{bank_person_name}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Bank Name:</b></p></div>
                    <div class="row col-sm-6"> <p>{bank_name}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>IFSC Code:</b></p></div>
                    <div class="row col-sm-6"> <p>{ifsc_code}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Account Number:</b></p></div>
                    <div class="row col-sm-6"> <p>{bank_account_number}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Account Type:</b></p></div>
                    <div class="row col-sm-6"> <p>{account_type}</p></div>
                    </div>
                    <div class="row col-md-4">
                    <div class="row col-sm-6"> <p><b>Loan Status:</b></p></div>
                    <div class="row col-sm-6">
                    <p>
                    <label className="badge badge-success" hidden={(loan_status=="under review")||(loan_status=="rejected")||(loan_status=="completed")}>approved</label>
                    <label className="badge badge-warning" hidden={(loan_status=="approved")||(loan_status=="rejected"||(loan_status=="completed"))}>under review</label>
                    <label className="badge badge-danger" hidden={(loan_status=="under review")||(loan_status=="approved")||(loan_status=="completed")}>rejected</label>                       
                    <label className="badge badge-primary" hidden={(loan_status=="under review")||(loan_status=="approved"||(loan_status=="rejected"))}>completed</label>                       
                    </p>
                    </div>
                    </div>

                    <hr />
                    <h3 class="card-title">DOCUMENTS AND ATTACHMENTS</h3>
                    <div class="row col-md-12">
                    <div class="row col-sm-6"> <p><b>Employee Remarks:</b></p></div>
                    <div class="row col-sm-6"> <p>{employee_remarks}</p></div>
                    </div>
                    <div class="row col-md-12">
                    <div class="row col-sm-6"> <p><b>Loan Remarks:</b></p></div>
                    <div class="row col-sm-6"> <p>{loan_remarks}</p></div>
                    </div>
                  
                    <div class="row mt-2" style={{textAlign:"left"}}>
                    <div class="row col-sm-6"><p><b>Document File: </b></p></div>
                    <div class="row col-sm-6"><p>{document_file_name}(<a href={document_file_link}>{document_file_link}</a>)</p></div>
                    </div>
                    <br/>
                    <a href={imgpath+self_certification_image_link}><button class="btn btn-dark" >View Document Attachment</button></a>

                    </div>

                    <row class="row mt-2">
                    <div class="row col-md-12"><img src={imgpath+cibil_proof_image} style={{height:"90vh", width:"100%"}} /></div>
                    </row>
                 {/*}   <row class="row mt-2">
                    <div class="row col-md-12"><img src={imgpath+self_certification_image_link} style={{height:"90vh", width:"100%"}} /></div>
                    </row> */}
                    </div>
                    </div>
                  </div>
                

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Employee_view_loan_application;