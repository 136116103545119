import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';

import './Resources/dashboard.css';
import axios from '../axios.js';
import Navbar from './Supportingfiles/navbar.js';
import Sidebar from './Supportingfiles/sidebar.js';
//import Create_loanrequests from './create_newaccounts';
const editId=sessionStorage.getItem("EditId00");


function Admin_manage_employee_permissions(){

  
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const logname=sessionStorage.getItem("logName00");


 
 //Error Variable
 const[errorvar,seterrorvar] = useState();

 
  //Variables
  const[data,setData] = useState([]);//SetData Variable
  //SETTING DATA VARIABLES
  const[permission_id,setpermission_id] = useState();//permssion_id
  const[customers_pm,setcustomers_pm]=useState();const updatecustomers_pm = (event) => { setcustomers_pm(event.target.value);}; const[customers_pm_er,setcustomers_pm_er]=useState();
  const[new_account_requests_pm,setnew_account_requests_pm]=useState();const updatenew_account_requests_pm = (event) => { setnew_account_requests_pm(event.target.value);}; const[new_account_requests_pm_er,setnew_account_requests_pm_er]=useState();
  const[cash_pm,setcash_pm]=useState();const updatecash_pm = (event) => { setcash_pm(event.target.value);}; const[cash_pm_er,setcash_pm_er]=useState();
  const[bank_pm,setbank_pm]=useState();const updatebank_pm = (event) => { setbank_pm(event.target.value);}; const[bank_pm_er,setbank_pm_er]=useState();
  const[incomes_pm,setincomes_pm]=useState();const updateincomes_pm = (event) => { setincomes_pm(event.target.value);}; const[incomes_pm_er,setincomes_pm_er]=useState();
  const[expenses_pm,setexpenses_pm]=useState();const updateexpenses_pm = (event) => { setexpenses_pm(event.target.value);}; const[expenses_pm_er,setexpenses_pm_er]=useState();
  const[loan_emis_pm,setloan_emis_pm]=useState();const updateloan_emis_pm = (event) => { setloan_emis_pm(event.target.value);}; const[loan_emis_pm_er,setloan_emis_pm_er]=useState();
  const[loan_requests_pm,setloan_requests_pm]=useState();const updateloan_requests_pm = (event) => { setloan_requests_pm(event.target.value);}; const[loan_requests_pm_er,setloan_requests_pm_er]=useState();
  const[loan_closure_requests_pm,setloan_closure_requests_pm]=useState();const updateloan_closure_requests_pm = (event) => { setloan_closure_requests_pm(event.target.value);}; const[loan_closure_requests_pm_er,setloan_closure_requests_pm_er]=useState();
  const[loan_schemes_pm,setloan_schemes_pm]=useState();const updateloan_schemes_pm = (event) => { setloan_schemes_pm(event.target.value);}; const[loan_schemes_pm_er,setloan_schemes_pm_er]=useState();
  const[transaction_history_pm,settransaction_history_pm]=useState();const updatetransaction_history_pm = (event) => { settransaction_history_pm(event.target.value);}; const[transaction_history_pm_er,settransaction_history_pm_er]=useState();
  const[customer_history_pm,setcustomer_history_pm]=useState();const updatecustomer_history_pm = (event) => { setcustomer_history_pm(event.target.value);}; const[customer_history_pm_er,setcustomer_history_pm_er]=useState();
  const[permission_status,setpermission_status]=useState();const updatepermission_status = (event) => { setpermission_status(event.target.value);}; const[permission_status_er,setpermission_status_er]=useState();

  
 //ERROR VARIABLES 
 const[error_msg,seterror_msg]=useState();


useEffect(()=>{
   
    getData();
    
    },[])


    

//******************* GET PERMISSIONS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(editId>0)){
 
 axios.post("crm-admin-get-employee-permissions", {
   Log_id:logid,
   Token:token,
   Edit_id:editId,


   })
 .then((response) => {

   

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
  // setData(response.data.data.data);
  setpermission_id(response.data.permission_id);
  setcustomers_pm(response.data.customers_pm);
  setnew_account_requests_pm(response.data.new_account_requests_pm);
  setcash_pm(response.data.cash_pm);
  setbank_pm(response.data.bank_pm);
  setincomes_pm(response.data.incomes_pm);
  setexpenses_pm(response.data.expenses_pm);
  setloan_emis_pm(response.data.loan_emis_pm);
  setloan_requests_pm(response.data.loan_requests_pm);
  setloan_closure_requests_pm(response.data.loan_closure_requests_pm);
  setloan_schemes_pm(response.data.loan_schemes_pm);
  settransaction_history_pm(response.data.transaction_history_pm);
  setcustomer_history_pm(response.data.customer_history_pm);
  setpermission_status(response.data.permission_status);

  }

  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Permissions !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET PERMISSIONS DATA FUNCTION END  ********************//


 


 
/***************** UPDATE PERMISSIONS FUNCTION START  ******************/
function update_permissions(){

  
  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&((logname!=null)||(logname!=" "))&&(editId>0)){


//No primary errors


  //Sending Data through axios
  axios.post("crm-admin-update-employee-permissions", { 
  Log_id:logid,
  Token:token,
  Edit_id:editId,
  customers_pm:customers_pm,
  new_account_requests_pm:new_account_requests_pm,
  cash_pm:cash_pm,
  bank_pm:bank_pm,
  incomes_pm:incomes_pm,
  expenses_pm:expenses_pm,
  loan_emis_pm:loan_emis_pm,
  loan_requests_pm:loan_requests_pm,
  loan_closure_requests_pm:loan_closure_requests_pm,
  loan_schemes_pm:loan_schemes_pm,
  transaction_history_pm:transaction_history_pm,
  customer_history_pm:customer_history_pm,
  permission_status:permission_status,
  }
    )
  
  .then((response) => {



  //Validation Errors
  if(response.data.validatorerror){
  seterror_msg("Cannot Update Permissions !");
  
  
  }
  //Invalid Entries
  if(response.data.error==102){ 

    seterror_msg("Cannot Update Permissions !");
  
  
  }
  
  //Branch Name or Branch Code Already Exists
  if(response.data.error==103){  seterror_msg(response.data.error_msg); return; }
  
  //Success
  if(response.data.successvar==1){ document.getElementById("success-card").style.display="block"; }

  //failure
  if(response.data.successvar==6){ seterror_msg("Cannot Update Permissions !"); }

  //Session Expired
  if(response.data.successvar==100){ seterror_msg("Session Expired !");  }


  }    );
  

  }
  //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
 sessionStorage.removeItem("logStatus00");
 sessionStorage.removeItem("logId00");
 sessionStorage.removeItem("logToken00");
 sessionStorage.removeItem("logName00");
 
 window.location.replace("/");
 
 
 }    

}

/***************** UPDATE PERMISSIONS FUNCTION END  ******************/

  

return(
    <>

<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Admin Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

   {/*}   <Sidebar /> */}
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          

                 {/*  SUCCESS CARD START */}
                 <div class="d-flex justify-content-center">
               <div class="col-md-6 grid-margin stretch-card" style={{position:"fixed",zIndex:"3",marginTop:"4rem",display:"none"}} id="success-card">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title  text-success">Permissions Updated Successfully !!!</h4>
                  
                    <blockquote class="blockquote">
                      <p class="mb-0"> Click the following button to continue.</p>
                      <a href="/admin-dashboard-manage-employee-permissions"><button class="mt-3 btn btn-outline-success">Continue</button></a>
                    </blockquote>
                  </div>
              
                </div>
              </div>
              </div>
              {/*  SUCCESS CARD END */}


          <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">ADMIN PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">MANAGE EMPLOYEE PERMISSIONS</p>
                  </a>
                </div>
          {/*}     <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i>New Loan Request</button>  */}
              </div>
            </div>


                <div class="card">
              
                  <div class="card-body" style={{textAlign:"left"}}>
                    <h4 class="card-title">Manage Permissions</h4>
                    <div class="form-sample">
                      <p class="card-description">manage all permissions</p>
                      <code>{error_msg}</code>
                      
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Customers</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="customers_pm" value={customers_pm} onChange={updatecustomers_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{customers_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">New Account Requests</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="new_account_requests_pm" value={new_account_requests_pm} onChange={updatenew_account_requests_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{new_account_requests_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Cash</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="cash_pm" value={cash_pm} onChange={updatecash_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{cash_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Bank</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="bank_pm" value={bank_pm} onChange={updatebank_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{bank_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Incomes</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="incomes_pm" value={incomes_pm} onChange={updateincomes_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{incomes_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Expenses</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="expenses_pm" value={expenses_pm} onChange={updateexpenses_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{expenses_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Loan EMIs</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="loan_emis_pm" value={loan_emis_pm} onChange={updateloan_emis_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{loan_emis_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Loan Requests</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="loan_requests_pm" value={loan_requests_pm} onChange={updateloan_requests_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{loan_requests_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Loan Closure Requests</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="loan_closure_requests_pm" value={loan_closure_requests_pm} onChange={updateloan_closure_requests_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{loan_closure_requests_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Loan Schemes</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="loan_schemes_pm" value={loan_schemes_pm} onChange={updateloan_schemes_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{loan_schemes_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Transaction History</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="transaction_history_pm" value={transaction_history_pm} onChange={updatetransaction_history_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{transaction_history_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Customer History</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="customer_history_pm" value={customer_history_pm} onChange={updatecustomer_history_pm} >
                              <option value="0">Hide</option>
                              <option value="1">Show</option>
                              </select>
                              <code>{customer_history_pm_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <div class="row">
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-6 col-form-label">Sidebar</label>
                            <div class="col-sm-6">
                              <select class="form-control" id="permission_status" value={permission_status} onChange={updatepermission_status} >
                              <option value="active">Show</option>
                              <option value="inactive">Hide</option>
                              </select>
                              <code>{permission_status_er}</code>
                            </div>
                          </div>
                        </div>       
                        </div>
                        <button class="btn btn-lg btn-success" style={{fontWeight:"bold"}} onClick={update_permissions}>Submit</button>
                      
        
                   
                    </div>
                  </div> 
                </div> 

                </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
    </>

);
}
//}

export default Admin_manage_employee_permissions;