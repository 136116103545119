import axios from 'axios';
const instance = axios.create({
   // baseURL: 'https://api.maavybooks.com/api/'
   //baseURL: 'http://localhost/thestartupconnect/thestartup/'

   //Online
   //baseURL : 'https://pragatiprathmik.com/api/',

   
    baseURL: 'https://api.pragatiprathmik.com/api/',
   //Offline
   //baseURL : 'http://127.0.0.1:8000/api/'
});
export default instance;